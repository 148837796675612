 <template>
	<div class="page">

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="80px">
			<el-form-item class="el_form_item" label="名称">
				<el-input class="el_input" v-model="form.name" clearable></el-input>
			</el-form-item>
			<el-form-item label-width="0">
				<el-button type="primary" @click="page_ser">查询</el-button>
				<el-button type="success" @click="add_view_open">添加</el-button>
			</el-form-item>
		</el-form>

		<!-- 表格 -->
		<div class="tab_height">
			<el-table :data="list.data" :border="true" :stripe="true" size="small" height="100%" v-loading="list.loading">
				<el-table-column label="创建时间" prop="create_time_text" width="150"></el-table-column>
				<el-table-column label="修改时间" prop="update_time_text" width="150"></el-table-column>
				<el-table-column label="名称" prop="name"></el-table-column>
				<el-table-column label="可删除" prop="could_del_text" width="80"></el-table-column>
				<el-table-column label="可修改" prop="could_edit_text" width="80"></el-table-column>
				<el-table-column label="备注" prop="mark" width="150"></el-table-column>
				<el-table-column label="状态" prop="status_text" width="80"></el-table-column>

				<!-- 操作行 -->
				<el-table-column label="操作" width="100">
					<template slot-scope="scope">
						<el-button v-if="scope.row.could_edit==1&&scope.row.could_del==1" @click="edit_view_open(scope.row)" size="mini" type="text">修改</el-button>
						<el-button v-if="scope.row.could_edit==1&&scope.row.could_del==1" @click="del(scope.row)" size="mini" type="text">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 修改弹出层 -->
		<edit 
			:menu_list="menu_list" 
			:data="edit.data" 
			:is_show="edit.is_show" 
			@edited="edit_view_close" 
		></edit>

		<!-- 添加弹出层 -->
		<add 
			:menu_list="menu_list" 
			:is_show="add.is_show" 
			@added="add_view_close" 
		></add>
	</div>
</template>

<script>
	import add from './add.vue'
	import edit from './edit.vue'
	export default {
		components:{
			add,
			edit,
		},
		data() {
			return {

				//菜单列表
				menu_list:[],

				//搜索条件
				form: {
					name:'',
				},

				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
				},

				//添加弹窗
				add:{
					is_show:0,
				},

				//修改弹窗
				edit:{
					data:{},
					is_show:0,
				}
			}
		},
		created() {
			
			//读取用户组数据
			this.get_page_data()
			
			//读取菜单列表
			this.get_menu_list()
		},
		methods: {

			//删除用户组
			del(item){

				//询问
				this.$my.other.confirm({
					content:"是否删除此用户组",
					confirm:()=>{
						this.$my.net.req({
							data:{
								mod:'admin',
								ctr:'ug_del_by_admin',
								ug_id:item.id
							},
							callback:(data)=>{
								this.$my.other.msg({
									type:'success',
									str:'操作成功'
								});
								this.get_page_data()
							},
						});
					}
				});
			},

			//添加页面
			add_view_open(){
				this.add.is_show++
			},
			add_view_close(){
				this.add.is_show=0
				this.get_page_data()
			},

			//打开用户组修改页面
			edit_view_open(item){
				this.edit.data=item
				this.edit.is_show++
			},
			edit_view_close(item){
				this.edit.is_show=0
				this.get_page_data()
			},
			
			//搜索
			page_ser(){
	
				//读取
				this.get_page_data()
			},

			//读取用户组数据
			get_page_data(){

				//加载中...
				if(this.list.loading){
					return;
				}

				//置为加载中
				this.list.loading=true;

				//读取数据
				this.$my.net.req({
					data:{
						mod:'admin',
						ctr:'ug_list_by_admin',
						p:1,
						num:1000
					},
					callback:(data)=>{

						//加载完毕
						this.list.loading=false

						//预处理
						for(let item of data.list){

							//创建时间
							item.create_time_text=this.$my.other.totime(item.create_time);

							//上次修改时间
							item.update_time_text=this.$my.other.totime(item.update_time);

							//状态(1:正常,2:停用)
							switch(item.status){
								case '1':item.status_text="正常";break;
								case '2':item.status_text="停用";break;
							}

							//是否可以删除(1:是,2:否)
							switch(item.could_del){
								case '1':item.could_del_text="是";break;
								case '2':item.could_del_text="否";break;
							}

							//是否可以修改(1:是,2:否)
							switch(item.could_edit){
								case '1':item.could_edit_text="是";break;
								case '2':item.could_edit_text="否";break;
							}

							//可用菜单按钮
							item.menu_and_btns_arr=JSON.parse(item.menu_and_btns)
						}

						//渲染
						this.list.data=data.list
					}
				})
			},

			//读取菜单列表
			get_menu_list(){
				
				this.$my.net.req({
					data:{
						mod:'admin',
						ctr:'menu_list_by_admin',
						status:1,
						p:1,
						num:1000
					},
					callback:(data)=>{

						//格式化主菜单
						let menu_buf={}
						let menu_main=[];
						let menu_children={};
						for(var item of data.list){

							item.controlled_btns_arr=item.controlled_btns.split(',')
							for(var i in item.controlled_btns_arr){
								if(!item.controlled_btns_arr[i]){
									item.controlled_btns_arr.splice(i,1);
								}
							}

							menu_buf[item.id]={...item};

							if(item.fid==0){
								menu_main.push(item)
							}else{
								if(!menu_children[item.fid])menu_children[item.fid]=[];
								menu_children[item.fid].push(item)
							}
						}

						//格式化子菜单
						for(var i in menu_main){
							menu_main[i].children=menu_children[menu_main[i].id]
						}

						//置入
						this.menu_list=menu_main
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>

	//修改/添加表单的额外权限菜单部分
	.menu_list{
		background-color:#eee;
		height: 500px;
		overflow: auto;
		padding: 0 20px;
		margin-top: 10px;
		width: 90%;
		.children_list{
			margin-left: 20px;
		}
	}

	//其他
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height:calc(100% - 60px);
	}
	.el_form_item{
		width: 16.6672%;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin: 0;
	}
</style>