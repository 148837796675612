 <template>
	<el-dialog title="添加用户组" top="1vh" width="60%" :visible.sync="is_show_in_page">
		<el-form label-width='90px'>
			<el-form-item label="用户组名称" >
				<el-input class="el_input" v-model="name" ></el-input>
			</el-form-item>
			<el-form-item label="用户组权限" >
				<div class="menu_list" v-if="is_show_in_page">
					<div v-for="(main_item,index) in menu_list" :key="index">
						<div>{{main_item.name}}</div>
						<div class="children_list">
							<div class="children_item" v-for="(children_item,index) in main_item.children" :key="index">
								<el-checkbox 
									:checked="menu_and_btns[children_item.id]?(menu_and_btns[children_item.id].checked):false" 
									@change="menu_selected(children_item)" 
								>{{children_item.name}}</el-checkbox>
								<div style="margin-left:20px" v-if="children_item.controlled_btns_arr.length>0">
									<span>权限按钮:&nbsp;&nbsp;</span>
									<el-checkbox 
										v-for="(btn_item,index) in children_item.controlled_btns_arr" 
										@change="controlled_btns_selected(children_item,btn_item)" 
										:key="index" 
										:disabled="menu_and_btns[children_item.id]?(!menu_and_btns[children_item.id].checked):false" 
										:checked="menu_and_btns[children_item.id]?(menu_and_btns[children_item.id].btns[btn_item]):false" 
									>{{btn_item}}</el-checkbox>
								</div>
								<div style="margin-left:20px" v-else>权限按钮:无</div>
							</div>
						</div>
					</div>
				</div>
			</el-form-item>
			<el-form-item >
				<el-button type="primary" @click="sub">提交</el-button>
			</el-form-item>
		</el-form>
	</el-dialog>
</template>

<script>
	export default {
		props:{
			menu_list:Array,
			data:Object,
			is_show:Number,
		},
		data() {
			return {

				//是否显示
				is_show_in_page:false,

				//用户组名称
				name:'',

				//可用菜单按钮
				menu_and_btns:{},
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){

					//打开弹出层
					this.is_show_in_page=true;
					
					//初始化
					this.init();

				}else{

					//关闭弹出层
					this.is_show_in_page=false;
				}
			}
		},
		methods: {

			menu_selected(children_item){
				if(this.menu_and_btns[children_item.id].checked){
					for(var btn_item in this.menu_and_btns[children_item.id].btns){
						this.$set(this.menu_and_btns[children_item.id].btns,btn_item,false)
					}
					this.menu_and_btns[children_item.id].checked=false
					
				}else{
					this.menu_and_btns[children_item.id].checked=true
				}
			},
			controlled_btns_selected(children_item,btn_item){
				if(this.menu_and_btns[children_item.id].btns[btn_item]){
					this.$set(this.menu_and_btns[children_item.id].btns,btn_item,false)
				}else{
					this.$set(this.menu_and_btns[children_item.id].btns,btn_item,true)
				}
			},

			//提交
			sub(){

				if(!this.name.trim()){
					this.$my.other.msg({
						type:'warning',
						str:"缺少用户组名称"
					});
					return;
				}

				//权限按钮
				let menu_and_btns={};
				for(var menu_id in this.menu_and_btns){
					let menu_item=this.menu_and_btns[menu_id]
					if(!menu_item.checked){
						continue
					}
					menu_and_btns[menu_id]=[]
					for(var btn in menu_item.btns){
						if(menu_item.btns[btn]){
							menu_and_btns[menu_id].push(btn)
						}
					}
				}

				//
				this.$my.other.confirm({
					content:"点击'确定'修改此项",
					confirm:()=>{
						this.$my.net.req({
							data:{
								mod:'admin',
								ctr:'ug_edit_by_admin',
								ug_id:this.data.id,
								name:this.name,
								menu_and_btns:menu_and_btns,
							},
							callback:(data)=>{
								
								//报告结果
								this.$my.other.msg({
									type:'success',
									str:"添加成功"
								});

								//关闭弹出层
								this.is_show_in_page=false;

								//通知
								this.$emit("edited");
							},
						});
					}
				});
			},

			//初始化
			init(){

				this.name=this.data.name

				let menu_and_btns_arr=this.data.menu_and_btns_arr

				for(var main_item of this.menu_list){

					for(var children_item of main_item.children){

						let menu_checked=false;
						if(menu_and_btns_arr[children_item.id]){
							menu_checked=true
						}

						let btns={}
						for(var btn_item of children_item.controlled_btns_arr){

							if(menu_checked){
								btns[btn_item]=menu_and_btns_arr[children_item.id].indexOf(btn_item)!=-1
							}else btns[btn_item]=false
							
						}

						this.$set(this.menu_and_btns,children_item.id,{
							checked:menu_checked,
							btns:btns,
						});
					}
				}
			},
		}
	}
</script>
<style lang="scss" scoped>

	//修改/添加表单的额外权限菜单部分
	.menu_list{
		background-color:#eee;
		height: 500px;
		overflow: auto;
		padding: 0 20px;
		margin-top: 10px;
		width: 90%;
		.children_list{
			margin-left: 20px;
			.children_item{
				display:flex;
				flex-wrap:wrap;
			}
		}
	}
</style>